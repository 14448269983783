import React from 'react'
import mans from "../../assets/homeTwobackman.png"
// lazyloading
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { NavLink } from 'react-router-dom';

const HomeTwo = () => {
    return (
        <>
            <div className="homeTwo">
                <div className="backImage"></div>
                <LazyLoadImage effect="blur" src={mans} alt="man image" srcSet="" />
            </div>
            <div className="textSide containerSelf">
                <h1>MURLIDHAR PRINTERS</h1>
                <p>MPPL was founded by Madhavjibhai Keralia in 1970 with a dream that was paved with an ample amount of struggle and hard work. His prolific career spanning over 50 years has seen him redefine Indian fashion. His two sons, Dinesh Keralia and Chandresh Keralia continue to carry his legacy forward while adding their own ingenius insights to the product creation by brining in cutting-edge technology. </p>
                <button> <NavLink to="/about"> Read More </NavLink> </button>
            </div>
        </>
    )
}

export default HomeTwo