import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from '../components/Navbar'
import HouseService from '../components/service/HouseService'
import ServiceHome from '../components/service/ServiceHome'
import Tie from '../components/service/Tie'
import Manufact from '../components/service/Manufact';
import Footer from '../components/Footer'
import ScrollTop from '../components/ScrollTop'

const Service = () => {
    return (
        <>
            <Navbar />
            <ServiceHome />
            <HouseService />
            <Tie />
            <Manufact />
            <Footer />
            <ScrollTop />
        </>
    )
}

export default Service