import React from 'react'
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper";
import sk from "../../assets/brand/SK-Logo.svg"
import circle from "../../assets/brand/circle.svg"
import red from "../../assets/brand/outlook.png"
import outlook from "../../assets/brand/Outlook.svg"
import mala from "../../assets/brand/mala.svg"
import eva from "../../assets/brand/eva.svg"
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { useEffect, useState } from 'react';

const HomeClient = () => {
  const [windowSize, setWindowSize] = useState(getWindowSize());
  const [slidesPerView, setSlidesPerView] = useState(3)
  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
      if (windowSize.innerWidth < 700) {
        setSlidesPerView(1)
      }
    }
    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  return (
    <>
      <div className='containerSelf homeClient' >
        <h1>OUR CLIENTS</h1>
        <p>We work with businesses that are disrupting the textile world. We set up our clients for success by provinding <nobr>un-matched  </nobr>  product possibilities and quality, with <nobr>speed-to-market</nobr>  solutions.</p>
      </div>
      <Swiper
        navigation={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,

        }}
        // navigation={true}
        // pagination={{
        //   clickable: true,
        // }}
        modules={[Autoplay, Navigation]}
        slidesPerView={slidesPerView}
        spaceBetween={30}
        className="mySwiper"
      >
        <SwiperSlide>
          <img src={sk} alt="sk logo" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={circle} alt="circle logo" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={red} alt="bk sarees" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={outlook} alt="outlook logo" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={mala} alt="mala logo" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={eva} alt="eva sarees logo" />
        </SwiperSlide>
      </Swiper>
    </>
  )
}
function getWindowSize() {
  const { innerWidth, innerHeight } = window;
  return { innerWidth, innerHeight };
}
export default HomeClient