import React from 'react'
import processHouse from "../../assets/services/processHouse.JPG"
import tie from "../../assets/services/tie.jpg"
import printing from "../../assets/services/printing.jpg"
import Manufacturing from "../../assets/services/manufacturing.jpg"
// lazyloading
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { NavLink } from 'react-router-dom'

const HomeService = () => {
    return (
        <div className='containerSelf homeService' >
            <h1>OUR SERVICES</h1>
            <div className="servicesFlex">
                <div className="imgFlex one">
                    <LazyLoadImage effect="blur" height="100%" width="100%" src={processHouse} alt="precess house" />
                </div>
                <div style={{ background: "#ECF8F8" }} className="titleFlex">Process House</div>
                <div className="imgFlex three">
                    <LazyLoadImage effect="blur" height="100%" width="100%" src={tie} alt="tie Image" />
                </div>
                <div style={{ background: "#FEF5E0", justifyContent: "flex-end" }} className="titleFlex">Tie & Dye</div>
            </div>
            <div className="servicesFlex">
                <div className="imgFlex one">
                    <LazyLoadImage effect="blur" height="100%" width="100%" src={printing} alt="printing image" />
                </div>
                <div className="titleFlex" style={{ background: "#FDEEDF" }}>Printing</div>
                <div className="imgFlex three">
                    <LazyLoadImage effect="blur" height="100%" width="100%" src={Manufacturing} alt="Manufacturing image" />
                </div>
                <div className="titleFlex" style={{ background: "#FDEFF1", justifyContent: "flex-end" }} >Manufacturing</div>
            </div>
            <button> <NavLink to="/service" style={{ color: "black" }} > View More </NavLink> </button>
        </div>
    )
}

export default HomeService