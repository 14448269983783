import React from 'react'
import homeGif from "../../assets/service/homepage.gif"

const ServiceHome = () => {
    return (
        <div className="serviveHome">
            <img src={homeGif} alt="homepage gif" />
        </div>
    )
}

export default ServiceHome