import React from 'react'

const AboutTeam = () => {
    return (
        <div className='containerSelf aboutTeam' >
            <h1>LEADERSHIP TEAM</h1>
            <p>Get to know the people leading the way at MPPL.</p>
            <div className="personBox topMen">
                <div className="founderImage"></div>
                <div className="personName">
                    <h1>Madhavjibhai Keralia</h1>
                    <p>Founder</p>
                </div>
            </div>
            <div className="personBox secondRow">
                <div className="flexImageWin pdl0">
                    <div className="founderImage ceoImage"></div>
                    <div className="personName">
                        <h1>Dinesh Keralia</h1>
                        <p>Chief Executive Officer</p>
                    </div>
                </div>
                <div className="flexImageWin pdr0">
                    <div className="founderImage cooImage"></div>
                    <div className="personName">
                        <h1>Chandresh Keralia</h1>
                        <p>Chief Operating Officer</p>
                    </div>
                </div>
            </div>
            <div className="personBox secondRow thirdOne">
                <div className="flexImageWin thirdRow pdl0">
                    <div className="founderImage mdImage"></div>
                    <div className="personName">
                        <h1>Dushyant Sharma</h1>
                        <p>Managing Director</p>
                    </div>
                </div>
                <div className="flexImageWin thirdRow">
                    <div className="founderImage hodImage"></div>
                    <div className="personName">
                        <h1>Jagjit Yadav</h1>
                        <p>Head of Design</p>
                    </div>
                </div>
                <div className="flexImageWin thirdRow pdr0">
                    <div className="founderImage poImage"></div>
                    <div className="personName">
                        <h1>Rajnikant Parmar</h1>
                        <p>People Operations Officer</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutTeam