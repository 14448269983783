import React from 'react'
import mail from "../../assets/contact/mail.svg"
import cell from "../../assets/contact/cell.svg"
import location from "../../assets/contact/location.svg"


const ContactLocation = () => {
    return (
        <>

            <div className='containerSelf contactLocation'>
                <div className="locationText">
                    <h1>We’re located at Ratanpar, Rajkot</h1>
                </div>
                <div className="map">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7377.298917500029!2d70.7772386!3d22.4045686!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3959c789729500a3%3A0x612d1de04a8e78e8!2sMurlidhar%20Printers%20Pvt.%20Ltd.!5e0!3m2!1sen!2sin!4v1660368205348!5m2!1sen!2sin" width="600" height="450" style={{ border: 0 }} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>

                </div>
            </div>
            <div className=' containerSelf contactLocation locBack'>
                <div className=" map locationText">
                    <h1>Contact Us</h1>
                    <div className="mail">
                        <a href=""> <img src={mail} alt="email" /> hello@murlidharprinters.com</a>
                    </div>
                    <div className="cellNo">
                        <a href=""> <img src={cell} alt="contact" /> +91 9099994215</a>
                    </div>
                </div>
                <div className="map">
                    <h1>Find Us At</h1>
                    <div className="loc">
                        <img src={location} alt="location" /> <p><img src={location} alt="location" /> Murlidhar Printers Pvt. Ltd.Survey No. 192-193-194 P, Morbi Highway Road, Ratanpar Village, Rajkot 360003 Gujarat</p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ContactLocation