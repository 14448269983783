import React from 'react'

const HomeThree = () => {
    return (
        <>
            <div className="mpplYellow containerSelf">
                <div className="backYellowImage"></div>
                <p>MPPL is powering high-fashion
                    Indian wear brands spanning the globe, while delivering unparalled excellence across the apparel value chain.</p>
            </div>
        </>
    )
}

export default HomeThree