import React from 'react'
import inn from "../../assets/homegif/Innovation.gif"
import pro from "../../assets/homegif/Profits.gif"
import que from "../../assets/homegif/Quality.gif"
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const HomeQuality = () => {
    return (
        <>
            <div className='containerSelf homeQuality' >
                <h1>WHAT MAKES US UNIQUE</h1>
                <p>MPPL has remained an industry leader in Indian apparel manufacturing for over 5 decades. <br />
                    Our team of creatives, <nobr>thought-leaders</nobr> and changermakers back up audacious thoughts with tenacious action. </p>
            </div>
            <div className="products containerSelf">
                <div className="illustartionBox">
                    <div className="illuBox">
                        <LazyLoadImage effect="blur" height="100%" width="100%" src={inn} alt="" />
                    </div>
                    <h1>Innovation</h1>
                    <p>We don’t follow trends. We start them. </p>
                </div>
                <div className="illustartionBox">
                    <div className="illuBox">
                        <LazyLoadImage effect="blur" height="100%" width="100%" src={que} alt="" />
                    </div>
                    <h1>Product Quality</h1>
                    <p>We set the bar high.
                        Excellence is the MPPL way.</p>
                </div>
                <div className="illustartionBox">
                    <div className="illuBox">
                        <LazyLoadImage effect="blur" height="100%" width="100%" src={pro} alt="" />
                    </div>
                    <h1>People Over Profits</h1>
                    <p>Empathy, Kindness & Equity.
                        There’s just not other way.
                    </p>
                </div>
            </div>
        </>
    )
}

export default HomeQuality