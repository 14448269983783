import React from 'react'
import AboutHome from '../components/AboutUs/AboutHome'
import AboutTeam from '../components/AboutUs/AboutTeam'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import AboutJoin from '../components/AboutUs/AboutJoin'
import AboutPuppy from '../components/AboutUs/AboutPuppy'
import ScrollTop from '../components/ScrollTop'
// import { PageAnimation } from "../Animation.js"
// import { motion } from 'framer-motion'

const AboutUs = () => {
    return (
        <div className='about'>
            <Navbar />
            <AboutHome />
            <AboutTeam />
            <AboutJoin />
            <AboutPuppy />
            <Footer />
            <ScrollTop />
        </div>
    )
}

export default AboutUs