import React from 'react'
import team from "../../assets/about/team.png"

const AboutHome = () => {
    return (
        <div className='aboutHome'>
            <img src={team} alt="our team" srcSet="" />
            <p className='containerSelf' >Meet our team of creative and unconventional thinkers. It takes an eclectic group of passionate operators to bring innovation at the forefront of textile. </p>
        </div>
    )
}

export default AboutHome