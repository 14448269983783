import React from 'react'
import { NavLink } from 'react-router-dom'

const HomeFour = () => {
    return (
        <>
            <div className="mpplYellow txtileBuild containerSelf">
                <div className="backYellowImage backBuildingImage"></div>
                <p>We’re more than just a textile company. <br />
                    We’re striving every day to create opportunities where none exist.</p>
                <button> <NavLink to="/contact" > Get In Touch </NavLink> </button>
            </div>
        </>
    )
}

export default HomeFour