import React from 'react'
import gif from "../../assets/homeGif.gif"
const HomeGif = () => {
    return (
        <div className='homeGif' >
            <img src={gif} alt="sarees gif" />
        </div>
    )
}

export default HomeGif