import React from 'react'
import Carousel from 'react-bootstrap/Carousel'
import one from "../../assets/service/manufac/one.gif"
import two from "../../assets/service/manufac/two.jpg"
import three from "../../assets/service/manufac/three.jpg"
import four from "../../assets/service/manufac/four.jpg"

const Manufact = () => {
    return (
        <div className='containerSelf houseService'>
            <h1>MANUFACTURING</h1>
            <p>Our expertise lies in developing products from concept to fruition. Our constant focus on innovation and our unbeatable range of products has enabled us to emerge as a trendsetter in the market.</p>
            <Carousel variant="dark" className='fullSize'>
                <Carousel.Item interval={2000}>
                    <img
                        className="d-block w-100"
                        src={one}
                        alt="First slide"
                    />
                </Carousel.Item>
                <Carousel.Item interval={2000}>
                    <img
                        className="d-block w-100"
                        src={two}
                        alt="Second slide"
                    />
                </Carousel.Item>
                <Carousel.Item interval={2000}>
                    <img
                        className="d-block w-100"
                        src={three}
                        alt="Third slide"
                    />
                </Carousel.Item>
                <Carousel.Item interval={2000}>
                    <img
                        className="d-block w-100"
                        src={four}
                        alt="Third slide"
                    />
                </Carousel.Item>
            </Carousel>
            <div className="smallSize">
                <div className="imgSlide">
                    <img src={one} alt="first slide" />
                </div>
                <div className="imgSlide">
                    <img src={two} alt="second slide" />
                </div>
                <div className="imgSlide">
                    <img src={three} alt="third slide" />
                </div>
                <div className="imgSlide">
                    <img src={four} alt="fourth slide" />
                </div>
            </div>
        </div>
    )
}

export default Manufact