import React from 'react'
import HomeOne from '../components/Home/HomeOne'
import HomeQuality from '../components/Home/HomeQuality'
import HomeService from '../components/Home/HomeService'
import HomeThree from '../components/Home/HomeThree'
import HomeTwo from '../components/Home/HomeTwo'
import HomeGif from '../components/Home/HomeGif'
import Navbar from '../components/Navbar'
import HomeClient from '../components/Home/HomeClient'
import HomeFour from '../components/Home/HomeFour'
import Footer from '../components/Footer'
import ScrollTop from '../components/ScrollTop'
// import { PageAnimation } from "../Animation.js"
// import { motion } from 'framer-motion'

const Home = () => {

    return (
        <>
            <Navbar />
            <HomeOne />
            <HomeTwo />
            <HomeThree />
            <HomeService />
            <HomeQuality />
            <HomeGif />
            <HomeClient />
            <HomeFour />
            <Footer />
            <ScrollTop />
        </>
    )
}

export default Home