import emailjs from '@emailjs/browser';
import contact from "../../assets/contact/back.png"
import TextField from '@mui/material/TextField';

const ContactHome = () => {
    function sendMail(e) {
        e.preventDefault();

        emailjs.sendForm('murlidharprinters', 'template_uh587zs', e.target, 't5QmPfzQDefDfwcMl')
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });
        e.target.reset();
    }
    return (
        <>
            <div className='contactHome' >
                <img src={contact} alt="contact home page" />
            </div>
            <div className="formData">
                <form onSubmit={sendMail}>
                    <h1>Get In Touch</h1>
                    <p>How can we help you?</p>
                    <TextField required id="standard-basic" name='name' fullWidth label="Full Name" variant="standard" margin="normal" />
                    <TextField required id="standard-basic" name='number' fullWidth label="Phone Number" variant="standard" margin="normal" />
                    <TextField required id="standard-basic" name='email' fullWidth label="Email Address" variant="standard" margin="normal" />
                    <TextField required id="standard-basic" name='message' fullWidth label="Type your message" variant="standard" margin="normal" />
                    <input type="submit" className='subButton' />
                </form>
            </div>
        </>
    )
}

export default ContactHome
