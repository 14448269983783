import React from 'react'
import Carousel from 'react-bootstrap/Carousel'
import one from "../../assets/service/tie/one.jpg"
import two from "../../assets/service/tie/two.jpg"
import three from "../../assets/service/tie/three.jpg"
import four from "../../assets/service/tie/four.gif"
const Tie = () => {
    return (
        <div className='containerSelf houseService' >
            <h1>TIE & DYE</h1>
            <p>Our dyeing processes incorporate traditional methods as well as the emerging techniques such as the designer spray effect by hand to offer an expansive range of designs while preserving the art of tie & dye.</p>
            <Carousel variant="dark" className='fullSize'>
                <Carousel.Item interval={2000}>
                    <img
                        className="d-block w-100"
                        src={one}
                        alt="First slide"
                    />
                </Carousel.Item>
                <Carousel.Item interval={2000}>

                    <img
                        className="d-block w-100"
                        src={two}
                        alt="Second slide"
                    />
                </Carousel.Item>
                <Carousel.Item interval={2000}>
                    <img
                        className="d-block w-100"
                        src={three}
                        alt="Third slide"
                    />
                </Carousel.Item>
                <Carousel.Item interval={2000}>
                    <img
                        className="d-block w-100"
                        src={four}
                        alt="Third slide"
                    />
                </Carousel.Item>
            </Carousel>
            <div className="smallSize">
                <div className="imgSlide">
                    <img src={one} alt="first slide" />
                </div>
                <div className="imgSlide">
                    <img src={two} alt="second slide" />
                </div>
                <div className="imgSlide">
                    <img src={three} alt="third slide" />
                </div>
                <div className="imgSlide">
                    <img src={four} alt="fourth slide" />
                </div>
            </div>
        </div>
    )
}

export default Tie