import React from 'react'
import insta from "../assets/social/instagram.svg"
import fb from "../assets/social/facebook.svg"
import ln from "../assets/social/linkedin.svg"
import call from "../assets/social/phone-call.png"
import mail from "../assets/social/email.png"
import logo from "../assets/mppl.svg"
import arrow from "../assets/social/arrow.svg"
import { NavLink } from 'react-router-dom'


const Footer = () => {
    return (
        <footer className='containerSelf' >
            <div className="footerMain">
                <div className="footerBox inTouch">
                    <h1>Get In Touch</h1>
                    <div className="mail">
                        <a href="mailto:hello@murlidharprinters.com"> <img src={mail} alt="mail icon" /> hello@murlidharprinters.com</a>
                    </div>
                    <div className="cellNo">
                        <a href="tel:+919099994215"> <img src={call} alt="call icon" /> +91 9099994215</a>
                    </div>
                    <div className="socialIcon">
                        <a href="https://www.facebook.com/people/Murlidhar-Printers/100085593942217/?is_tour_dismissed=true" target="_blank" >
                            <img src={fb} alt="fb logo" />
                        </a>
                        <a href="https://instagram.com/murlidharprinters?igshid=ZDYzZTNjNWY=" target="_blank">
                            <img src={insta} alt="insta logo" />
                        </a>
                        <a href="https://www.linkedin.com/company/83510309/admin/" target="_blank">
                            <img src={ln} alt="linkedin logo" />
                        </a>


                    </div>
                </div>
                <div className="footerBox logoDetails ">
                    <div className="logoName">
                        <img src={logo} alt="mppl logo" />
                        <h1>MPPL</h1>
                    </div>
                    <p>Murlidhar Printers Pvt. Ltd. Survey No. 192-193-194 P,
                        Morbi Highway Road, Ratanpar Village, Rajkot 360003 Gujarat </p>
                </div>
                <div className="footerBox links">
                    <div className="homeLink">
                        <NavLink to="/" > Home <img src={arrow} alt="arrow icon" /></NavLink>
                    </div>
                    <div className="homeLink">
                        <NavLink to="/about" >About Us <img src={arrow} alt="arrow icon" /></NavLink>
                    </div>
                    <div className="homeLink">
                        <NavLink to="/service" >Our Services <img src={arrow} alt="arrow icon" /></NavLink>
                    </div>
                    <div className="homeLink">
                        <NavLink to="/contact" >Contact Us <img src={arrow} alt="arrow icon" /></NavLink>
                    </div>
                </div>
            </div>
            <div className="rights">
                {new Date().getFullYear()} | Designed by Unpause
                {/* develop by Yash Ghori (https://github.com/yashghori) */}
            </div>
        </footer>
    )
}

export default Footer