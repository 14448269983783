import React, { useRef, useState } from 'react'
import HomeVideo from "../../assets/mpplVideo.mp4"
import play from "../../assets/navIcons/play.png"
import pause from "../../assets/navIcons/pause.png"

const HomeOne = () => {
    const [isPlaying, setIsPlaying] = useState(true)
    const plays = useRef(null)
    const playControl = () => {
        isPlaying ? plays.current.pause() : plays.current.play();
        setIsPlaying(!isPlaying)
    }
    return (
        <>
            <video ref={plays} src={HomeVideo} muted alt="" autoPlay={"autoplay"} preload="auto" loop ></video>
            <a className='playPause' onClick={playControl} >{isPlaying ? <img src={pause} alt="pause icon" /> : <img src={play} alt="pause icon" />}</a>
        </>
    )
}

export default HomeOne