import React from 'react'
import Carousel from 'react-bootstrap/Carousel'

import one from "../../assets/service/houseService/one.gif"
import two from "../../assets/service/houseService/two.jpg"
import three from "../../assets/service/houseService/three.gif"
import four from "../../assets/service/houseService/four.jpg"

const HouseService = () => {
    return (
        <div className='containerSelf houseService' >
            <h1>PROCESS HOUSE</h1>
            <p>Our textile processing house has all open-width machines for mercerizing, dyeing, finishing of fabrics. Keeping environmental safety in mind, there are effluent treatment plants to treat the discharged water.</p>
            <Carousel variant="dark" className='fullSize' >
                <Carousel.Item interval={2000}>
                    <img
                        className="d-block w-100"
                        src={one}
                        alt="First slide"
                    />
                </Carousel.Item>
                <Carousel.Item interval={2000}>
                    <img
                        className="d-block w-100"
                        src={two}
                        alt="Second slide"
                    />
                </Carousel.Item>
                <Carousel.Item interval={2000}>
                    <img
                        className="d-block w-100"
                        src={three}
                        alt="Third slide"
                    />
                </Carousel.Item>
                <Carousel.Item interval={2000}>
                    <img
                        className="d-block w-100"
                        src={four}
                        alt="Third slide"
                    />
                </Carousel.Item>
            </Carousel>
            <div className="smallSize">
                <div className="imgSlide">
                    <img src={one} alt="first slide" />
                </div>
                <div className="imgSlide">
                    <img src={two} alt="second slide" />
                </div>
                <div className="imgSlide">
                    <img src={three} alt="third slide" />
                </div>
                <div className="imgSlide">
                    <img src={four} alt="fourth slide" />
                </div>
            </div>
        </div>

    )
}

export default HouseService