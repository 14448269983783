import React from 'react'
import { useState } from 'react'
import close from "../assets/navIcons/close.svg"
import menu from "../assets/navIcons/menu.svg"
import { NavLink } from 'react-router-dom'
import logo from "../assets/mppl.svg"

const Navbar = () => {
    // change navbar color on scroll
    const [color, setColor] = useState(false);
    const changeColor = () => {
        if (window.scrollY >= 90) {
            setColor(true);
        } else {
            setColor(false);
        }
    }
    window.addEventListener("scroll", changeColor)
    // open and close navbar
    const [isOpen, setIsOpen] = useState(false);
    return (
        <nav className={color ? "containerSelf nav-bg " : "containerSelf nav"}  >
            <div className="Navlogo">
                <NavLink to="/">
                    <img src={logo} alt="mppl logo" />
                </NavLink>
                <NavLink to="/"><h1>MPPL</h1></NavLink>
            </div>
            <div className="NavItems desktop">
                <li><NavLink to="/">Home</NavLink></li>
                <li><NavLink to="/about">About Us</NavLink></li>
                <li><NavLink to="/service">Our Service</NavLink></li>
                <li><NavLink to="/contact">Contact Us</NavLink></li>
            </div>
            <div className="mobile">
                <div onClick={() => { setIsOpen(!isOpen) }} className="menuIcon"><img src={menu} alt="manu icon" /></div>
                {isOpen ? <>
                    <div className="NavItems mobileItem">
                        <p onClick={() => { setIsOpen(!isOpen) }} ><img src={close} alt="close icon" /></p>
                        <li><NavLink to="/">Home</NavLink></li>
                        <li><NavLink to="/about">About Us</NavLink></li>
                        <li><NavLink to="/service">Our Service</NavLink></li>
                        <li><NavLink to="/contact">Contact Us</NavLink></li>
                    </div>
                </> : <></>}

            </div>
        </nav>
    )
}

export default Navbar