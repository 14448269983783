import React from 'react'
import puppy from "../../assets/about/puppy.png"
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const AboutPuppy = () => {
    return (
        <div className='containerSelf aboutPuppy' >
            <div className="puupyFlex">
                <div className="ppyImg">
                    <LazyLoadImage effect="blur" height="100%" width="100%" src={puppy} alt="our chief officer aiko andlexi" />
                </div>
                <div className="puppyText">
                    <h1>Meet Aiko & Lexi, our <br /> Chief Fun Officers.</h1>
                    <p>It’s never a dull day at the office with them around!</p>
                </div>
            </div>
        </div>
    )
}

export default AboutPuppy