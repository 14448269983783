import React from 'react'
import { NavLink } from 'react-router-dom'

const AboutJoin = () => {
    return (
        <div className='containerSelf aboutJoin' >
            <h1>JOIN OUR TEAM</h1>
            <p>We are problem solvers. We are leaders. We are hustlers. <br /> Are you?</p>
            <button> <NavLink to="/contact" > Join Us</NavLink></button>
        </div>
    )
}

export default AboutJoin