import React from 'react'
import ContactHome from '../components/contact/ContactHome'
import ContactLocation from '../components/contact/ContactLocation'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import ScrollTop from '../components/ScrollTop'

const Contact = () => {
    return (
        <div className='contact' >
            <Navbar />
            <ContactHome />
            <ContactLocation />
            <Footer />
            <ScrollTop />
        </div>
    )
}

export default Contact